#footer {
    background-color: var(--color-red-dark);
}

#footer h5{
    color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
}

#footer h5 a{
    color: white;
    font-weight: bold;
}
