body {
  margin: 0;
  font-family: 'Montserrat';
}



:root {
  --color-red-dark : #BC2F1D;
  --color-red-light: #FFB4AA;
}

@media (max-width: 1200px) {
  html {
    font-size: 14px;
}
}

@media (max-width: 992px) {
  html {
      font-size: 12px;
  }
  .navbar-hna {
    height: 10rem !important;
  }
  #about-mymy .bgmymy-about img.img-bg2 {
    width: 250% !important;
    max-height: 100%;
    right: -60% !important;
    object-fit: contain;
  }
}
@media (max-width: 768px) {
  html {
      font-size: 8px;
  }
  .navbar-hna {
    height: 10rem !important;
  }
  #about-mymy .bgmymy-about img.img-bg2 {
    width: 250% !important;
    max-height: 100%;
    right: -60% !important;
    object-fit: contain;
  }
}


@media (max-width: 576px) {
  html {
      font-size: 6px
  }
  .navbar-hna {
    height: 10rem !important;
  }

  #about-mymy .block-2 .border-box-mymy,#about-mymy .block-2 .box-content,#about-mymy .bgmymy-about .img-about,#about-mymy .slider {
    height: 300px !important;
  }

  #about-mymy .slider figure img {
    height: 200px !important;
  }


  #about-mymy .block-2 .border-box-mymy::before { 
    height: 298px !important;
    width: calc(100% + 200px) !important;
  }

  #image-mymy img, #audio-mymy img{
    padding-top : 5px;
    padding-bottom : 5px;
  }

  .col-4,.col-8 {
    padding-right: 5px;
    padding-left: 5px;
  }

  #about-mymy .bgmymy-about img.img-bg2 {
    width: 250% !important;
    max-height: 100%;
    right: -60% !important;
    object-fit: contain;
  }

  h1 {
    font-size: 2rem;
  }

  span.line-red {
    width: 50px !important;
  }
}